.speak-monkey {
  width: 420px;
  text-align: center;
}

.speak-monkey-btn {
  display: inline-block;
  width: 200px;
  height: 200px;
  background: #fafafa;
  border: 1px solid #b1b1b1;
  border-radius: 10px;
  font-size: 120px;
  text-align: center;
}