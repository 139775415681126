.comment {
  flex: 1;
  text-align: right;

  &-loading {
    color: #fafafa;
    font-size: 30px;
    text-align: center;
  }

  &-list {
    display: inline-block;
    margin: 0;
    padding: 0;
    max-width: 360px;
    height: 500px;
    list-style: none;
    overflow-y: auto;

    &-item {
      margin: 8px 0;
      text-align: right;

      div {
        display: inline-block;
        padding: 10px 24px;
        background: rgba(250, 250, 250, 0.6);
        border: 1px solid #bababa;
        border-radius: 1em;
        text-align: left;
      }
    }
  }

  &-not-support {
    color: #fafafa;
    font-size: 30px;
    text-align: center;
  }

  & .none {
    display: none;
  }
}