button {
  cursor: pointer;
}

.btn-start {
  padding: 8px 16px;
  border: 1px solid #333;
  border-radius: 3px;
  transition: .2s;

  &:hover {
    background: #333;
    color: #fff
  }
}
